import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import 'materialize-css/dist/css/materialize.min.css'
import App from "./routes/App";
import M from 'materialize-css'
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

M.AutoInit()

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
